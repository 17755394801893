.App {
  background-color: #141619;
  color: #5664e8;
  text-align: center;
}

.App-header {
  background-color: #141619;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 1%;
}

.header-image {
  transition: height 1s ease-in-out;
  position: sticky;
  top: 5%;
  left: 1%;
}

.App-link {
  color: #61dafb;
}
